<template>
  <div class="box" :style="bg">
    <nav-router :routerList="useRouterList">
      <img :src="icon" alt="" />
    </nav-router>
    <div class="content">
      <div class="content-header">
        <div class="reference">
          <el-button class="sign-btn" @click="handleActivity">
            <img src="@/assets/images/interactive/sign-icon.png" alt="" />
            签到
          </el-button>
          <el-button class="sign-btn" @click="handleExam">
            <img src="@/assets/images/interactive/answer-icon.png" alt="" />
            答题
          </el-button>
          <el-button class="sign-btn" @click="handleVote">
            <img src="@/assets/images/interactive/vote-icon.png" alt="" />
            投票
          </el-button>
          <el-button class="sign-btn" @click="handleMsg">
            <img src="@/assets/images/interactive/msg-icon.png" alt="" />
            留言墙
          </el-button>
        </div>
      </div>
      <div class="sign-list" @scroll="scrollEvent">
        <template v-if="activeName == 'activity'">
          <div class="sign-item" v-for="item in activityList" :key="item.id">
            <div class="sing-item-left">
              <span>{{ item.name }}</span>
            </div>
            <div class="sign-item-right">
              <span class="click">点击可放大</span>

              <el-popover
                placement="bottom"
                width="4rem"
                trigger="click"
                style="border: none"
                :ref="'popover' + item.id"
              >
                <div class="pop-content">
                  <vue-qrcode
                    tag="img"
                    :value="
                      WX_URL +
                      `/sign?activityId=${item.id}&deviceCode=${deviceCode}&userId=${user.id}`
                    "
                    class="qrcode"
                  />
                  <p>微信扫码签到</p>
                </div>
                <div @click="qrcodeId = item.id" slot="reference">
                  <vue-qrcode
                    tag="img"
                    :value="
                      WX_URL +
                      `/sign?activityId=${item.id}&deviceCode=${deviceCode}&userId=${user.id}`
                    "
                    style="width: 1.16rem; height: 1.16rem"
                  />
                </div>
              </el-popover>
            </div>
          </div>
        </template>

        <template v-else-if="activeName == 'exam'">
          <div class="sign-item" v-for="item in examList" :key="item.id">
            <div class="sing-item-left">
              <span>{{ item.name }}</span>
            </div>
            <div class="sign-item-right" @click="JumpToAnswer(item)">
              点击进入>
            </div>
          </div>
        </template>

        <template v-else-if="activeName == 'vote'">
          <div class="sign-item" v-for="item in voteList" :key="item.id">
            <div class="sing-item-left">
              <span>{{ item.name }}</span>
            </div>
            <div class="sign-item-right" @click="JumpToVote(item)">
              点击进入>
            </div>
          </div>
        </template>

        <template v-else>
          <!-- height="936px" -->
          <!-- <el-carousel height="9.5rem" :interval="2000" :autoplay="true" indicator-position="none"> -->

          <!-- <transition-group name="fade" tag="div" class="carousel-container">
            <div
              class="msg-container"
              v-for="(msg, index) in initMsgList"
              :key="msg.content + index"
            >
              <div class="msg-header">
                <img class="msg-header__title" :src="msg.wxAvatarUrl" alt="" />
              </div>
              <div class="msg-content">
                {{ msg.content }}
              </div>
              <div class="msg-date">{{ msg.createTime }}</div>
            </div>
          </transition-group> -->

          <!-- <div class="carousel-container">
              <div class="msg-container" v-for="msg in msgList" :key="msg.id">
                <div class="msg-header">
                  <img class="msg-header__title" :src="msg.wxAvatarUrl" alt="" />
                </div>
                <div class="msg-content">
                  {{ msg.content }}
                </div>
                <div class="msg-date">{{ msg.createTime }}</div>
              </div>
            </div> -->
          <!-- </el-carousel> -->

          <transition-group name="fade" tag="ul" class="list">
            <li v-for="item in initMsgList" :key="item.id" class="list-item">
              <div class="msg-header">
                <img class="msg-header__title" :src="item.wxAvatarUrl" alt="" />
              </div>
              <div class="msg-content">
                {{ item.content }}
              </div>
              <div class="msg-date">{{ item.contentTime }}</div>
            </li>
          </transition-group>
        </template>
      </div>
    </div>
    <menu-pop :activeItem="4"></menu-pop>
    <back :pageDepth="2"></back>
  </div>
</template>

<script>
import menuPop from '@/components/menuPop';
import back from '@/components/back';
import navRouter from '@/components/navRouter';
import { API_MODULE } from '@/request/api';
const redIcon = require('@/assets/images/dangHistory/home.png');
const blueIcon = require('@/assets/images/dangHistory/home-blue.png');
import { mapGetters, mapMutations } from 'vuex';
import vueQrcode from '@chenfengyuan/vue-qrcode';
import BrowserType from '@/utils/user-agent.js';
export default {
  name: 'WISDOM_INTERACTION',
  components: {
    menuPop,
    navRouter,
    back,
    vueQrcode,
  },
  data() {
    return {
      icon: redIcon,
      bg: {
        backgroundColor: '#E03838',
      },
      bgRed: {
        backgroundColor: '#E03838',
      },
      bgBlue: {
        backgroundColor: '#2070DB',
      },
      activeName: 'activity',
      activityList: [],
      examList: [],
      voteList: [],
      msgList: [],
      allMsgList: [],
      initMsgList: [],
      nextIndex: 4,
      // 设备编码
      deviceCode: '',
      user: {},
      timer: null,
      qrcodeId: null,
    };
  },
  methods: {
    ...mapMutations('skin', ['setRouterList']),
    getActivityList() {
      this.GET(API_MODULE.GET_SIGN_IN_ACTIVITY)
        .then((res) => {
          if (res.code == 0 || res.code == 200) {
            this.activityList = res.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getAnswerList() {
      this.GET(API_MODULE.GET_EXAM_LIST).then((res) => {
        if (res.code == 0 || res.code == 200) {
          this.examList = res.data;
        }
      });
    },

    getVoteList() {
      this.GET(API_MODULE.GET_VOTE_LIST).then((res) => {
        if (res.code == 0 || res.code == 200) {
          this.voteList = res.data;
        }
      });
    },

    getInitMsgList() {
      this.GET(API_MODULE.GET_MSG_LIST, { snCode: this.deviceCode }).then(
        (res) => {
          if (res.code == 0 || res.code == 200) {
            this.allMsgList = res.data;
            this.initMsgList = res.data.slice(0, 4);
          }
        }
      );
    },

    getNewMsgList() {
      this.GET(API_MODULE.GET_MSG_LIST, { snCode: this.deviceCode }).then(
        (res) => {
          if (res.code == 0 || res.code == 200) {
            const data = res.data;
            if (data.length - this.allMsgList.length > 0) {
              const newData = data.slice(
                0,
                data.length - this.allMsgList.length
              );
              newData.forEach((element) => {
                element.isNew = true;
              });
              this.allMsgList.splice(this.nextIndex, 0, ...newData);
            }
          }
        }
      );
    },

    handleActivity() {
      this.activeName = 'activity';
      this.getActivityList();
      this.clearTimer();
    },

    handleExam() {
      this.activeName = 'exam';
      this.getAnswerList();
      this.clearTimer();
    },

    handleVote() {
      this.activeName = 'vote';
      this.getVoteList();
      this.clearTimer();
    },

    handleMsg() {
      this.activeName = 'msg';
      this.getInitMsgList();
      let that = this;
      this.timer = setInterval(function () {
        that.getNewMsgList();
        // nextIndex === 所有消息时，重置循环
        console.log(that.initMsgList.length < 4);
        if (that.initMsgList.length < 4) {
          that.nextIndex = that.initMsgList.length;
          that.allMsgList[that.nextIndex] &&
            that.initMsgList.push(that.allMsgList[that.nextIndex++]);
        } else {
          if (that.nextIndex === that.allMsgList.length) that.nextIndex = 0;
          that.initMsgList.push(that.allMsgList[that.nextIndex++]);
          that.initMsgList.splice(0, 1);
        }
      }, 2500);
    },

    JumpToAnswer(item) {
      this.$router.push({
        path: '/layout/interactive/answer',
        query: { id: item.id },
      });

      const path = `/layout/interactive/answer?id=${item.id}`;
      this.useRouterList.push({
        name: item.name,
        path: path,
      });
      this.setRouterList(this.useRouterList, path);
    },
    JumpToVote(item) {
      this.$router.push({
        path: '/layout/interactive/vote',
        query: { id: item.id, status: item.status },
      });
      const path = `/layout/interactive/vote?id=${item.id}&status=${item.status}`;
      this.useRouterList.push({
        name: item.name,
        path: path,
      });
      this.setRouterList(this.useRouterList, path);
    },
    handleAgent() {
      if (this.SNCODE_STATRUS == 1) {
        // 这两个方法是在index.html 引入的js文件里的
        // eslint-disable-next-line no-undef
        init_qt();
        // eslint-disable-next-line no-undef
        GetCpuID();
        this.deviceCode = document.getElementById('cpu_id').value;
      } else {
        this.deviceCode = '';
      }
    },
    handleDevice() {
      if (this.SNCODE_STATRUS == 1) {
        window.androidAPI.callAndroidMethod('getDeviceId', '');
        this.deviceCode = document.getElementById('device_id').value;
      } else {
        this.deviceCode = '';
      }
    },
    clearTimer() {
      clearInterval(this.timer);
      this.timer = null;
    },
    //监听滚动
    scrollEvent() {
      if (this.qrcodeId) {
        this.$refs['popover' + this.qrcodeId][0].doClose();
        this.qrcodeId = null;
      }
    },
  },
  computed: {
    ...mapGetters('skin', ['useSubject', 'useRouterList']),
  },
  created() {
    if (this.useSubject === 'red') {
      this.icon = redIcon;
      this.bg = this.bgRed;
    } else {
      this.icon = blueIcon;
      this.bg = this.bgBlue;
    }
    this.user = JSON.parse(window.localStorage.getItem('USER'));
    this.getActivityList();
    // 保存路径
    this.setRouterList([{ name: '智慧互动', path: this.$route.fullPath }]);

    if (window.localStorage.getItem('isd')) {
      this.isd = window.localStorage.getItem('isd');
    }
  },
  mounted() {
    this.userAgent = BrowserType();
    if (!this.isd) {
      if (this.userAgent.system === 'android') {
        this.handleDevice();
      }
      if (this.userAgent.system === 'windows') {
        this.handleAgent();
      }
    } else {
      this.deviceCode = '0000000000';
    }
  },
  watch: {
    useSubject(val) {
      if (val === 'red') {
        this.icon = redIcon;
        this.bg = this.bgRed;
      } else {
        this.icon = blueIcon;
      }
    },
  },
  destroyed() {
    this.clearTimer();
  },
  activated() {
    // 保存路径
    this.setRouterList([{ name: '智慧互动', path: this.$route.fullPath }]);
  },
  beforeRouteLeave(to, from, next) {
    if (to.name !== 'Answer' && to.name !== 'Vote') {
      this.$store.commit('REMOVE_KEEP_ALIVE', from.name);
    }
    next();
  },
};
</script>

<style lang="scss" scoped>
@import '@/themes/_handle.scss';

.pop-content {
  width: 4rem;
  height: 4.66rem;
  @include background_color('background-medium');
  text-align: center;
  color: #ffffff;
  font-size: 0.36rem;

  .qrcode {
    width: 3rem;
    margin-top: 0.56rem;
    margin-bottom: 0.2rem;
  }
}

.box {
  min-height: 13.6rem;
  background-size: cover;

  .content {
    padding: 0.6rem;

    .content-header {
      .reference {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;

        .sign-btn {
          // float: right;
          width: 3.2rem;
          height: 1rem;
          background: #cca766;
          opacity: 1;
          border-radius: 0.05rem;
          font-size: 0.31rem;
          color: #ffffff;
          text-align: center;
          border: 1px solid #cca766;
          line-height: 0.5rem;
          margin-left: 0.4rem;
          img {
            margin-right: 0.1rem;
            vertical-align: text-top;
            width: 0.3rem;
            height: 0.3rem;
          }
        }
      }

      .sign-btn:active {
        border: 1px solid #fff;
      }
    }

    .sign-list {
      margin-top: 0.4rem;
      height: 10.4rem;
      overflow-x: hidden;
      overflow-y: scroll;

      .sign-item {
        width: 100%;
        height: 1.6rem;
        background: #ffffff;
        border-width: 0.1rem;
        border-style: solid;
        @include border_color('border-light');

        border-radius: 0.05rem;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 0.78rem;
        padding-right: 1rem;
        margin-bottom: 0.6rem;

        .sing-item-left {
          font-size: 0.48rem;
          color: #555555;
          display: flex;
          align-items: center;

          img {
            width: 1rem;
            height: 1rem;
            border-radius: 0.1rem;
            font-weight: bold;
            margin-right: 0.5rem;
          }
        }

        .sign-item-right {
          font-weight: bold;
          font-size: 0.36rem;
          display: flex;
          align-items: center;

          @include font_color('font-medium');

          .click {
            color: #c3c3c3;
          }

          .exam-click {
            color: #c3c3c3;
            font-size: 0.3rem;
          }
        }
      }
      .carousel-container {
        display: flex;
        height: 100%;
        padding-top: 0.4rem;
        .msg-container {
          transition: all 0.6s;
          display: inline-block;
          padding-top: 0.7rem;
          display: flex;
          flex-direction: column;
          margin-right: 0.8rem;
          margin-bottom: 0.8rem;
          background-color: #f7f7f7;
          position: relative;

          .msg-header__title {
            height: 5rem;
            width: 4rem;
            border-radius: 5%;
          }

          .msg-content {
            width: 4.59rem;
            color: #6f6f6f;
            font-size: 0.28rem;
            padding: 0.4rem;
            text-align: left;
          }
          .msg-date {
            font-size: 0.24rem;
            color: #b2b2b2;
            align-self: flex-end;
            padding-right: 0.4rem;
            padding-bottom: 0.1rem;
            position: absolute;
            bottom: 0.5rem;
          }
        }
      }

      .carousel-container-top {
        display: flex;
        height: 100%;
        padding-top: 1rem;
        .msg-container {
          padding-top: 0.7rem;
          display: flex;
          flex-direction: column;
          margin-right: 0.8rem;
          margin-bottom: 0.8rem;
          background-color: #f7f7f7;
          position: relative;

          .msg-header__title {
            height: 5rem;
            width: 4rem;
            border-radius: 5%;
          }

          .msg-content {
            width: 4.59rem;
            color: #6f6f6f;
            font-size: 0.28rem;
            padding: 0.4rem;
            text-align: left;
          }
          .msg-date {
            font-size: 0.24rem;
            color: #b2b2b2;
            align-self: flex-end;
            padding-right: 0.4rem;
            padding-bottom: 0.1rem;
            position: absolute;
            bottom: 0.5rem;
          }
        }
      }
    }
  }
}

// // .fade-move{transition: transform 0.6s;} /* Vue过渡提供的元素位移平滑处理*/
// ::v-deep .el-carousel__container > button {
//   display: none;
// }

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-enter {
  transform: translateY(20px);
}

.fade-leave-to {
  transform: translateY(-20px);
}

.fade-leave-active {
  position: absolute;
  right: 0;
}

/*leave-active必须绝对定位移除元素才有过渡效果*/
.list {
  display: flex;
  height: 100%;
  padding-top: 0.4rem;

  .list-item {
    transition: all 0.6s;
    list-style: none;

    padding-top: 0.7rem;
    display: flex;
    flex-direction: column;
    margin-right: 0.8rem;
    margin-bottom: 0.8rem;
    background-color: #f7f7f7;
    position: relative;

    .msg-header__title {
      height: 5rem;
      width: 4rem;
      border-radius: 5%;
    }

    .msg-content {
      width: 4.59rem;
      color: #6f6f6f;
      font-size: 0.28rem;
      padding: 0.4rem;
      text-align: left;
    }
    .msg-date {
      font-size: 0.24rem;
      color: #b2b2b2;
      align-self: flex-end;
      padding-right: 0.4rem;
      padding-bottom: 0.1rem;
      position: absolute;
      bottom: 0.5rem;
    }
  }
}
</style>
